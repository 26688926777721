import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
import InitLoader from "../layout/InitLoader";
import MasterLayout from "../layout/MasterLayout";
import ServiceComponent from "../components/ServiceComponent";

const Service = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<InitLoader />}>
          <Helmet>
            <title>Services || Ocean Developing Web & Mobile Solutions in Fuerteventura</title>
            <meta
              name="description"
              content="Services || Ocean Developing Web & Mobile Solutions in Fuerteventura"
            />
          </Helmet>
          <ServiceComponent />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default Service;
